import "core-js/modules/es.array.push.js";
export default {
  name: 'shop',
  components: {},
  data() {
    return {
      firstLoad: true,
      orderStateIndex: 0,
      orderStateList: [{
        state: -1,
        name: '全部'
      },
      // {
      //   state:0,
      //   name:'待付款'
      // },
      {
        state: 1,
        name: '待发货'
      }, {
        state: 2,
        name: '待收货'
      }, {
        state: 3,
        name: '已完成'
      }
      // {
      //   state:4,
      //   name:'已取消'
      // },
      ],

      itemList: []
    };
  },
  async created() {},
  async mounted() {},
  methods: {
    orderStateChange(index) {
      this.orderStateIndex = index;
      this.$refs.parentListContent.reload();
    },
    itemClick(item) {
      this.$router.push(`/orderDetail/${item.orderType}/${item.orderInfo.id}`);
    },
    onDataLoad: async function (options, callback) {
      if (this.firstLoad == true) {
        let state = this.$route.query.state;
        if (state) {
          for (let i in this.orderStateList) {
            if (state == this.orderStateList[i].state) {
              this.orderStateIndex = Number(i);
              break;
            }
          }
        }
        this.firstLoad = false;
      }
      let orderState = this.orderStateList[this.orderStateIndex].state;
      if (orderState == 1 || orderState == 2 || orderState == 3) {
        options.state = orderState;
      }
      const result = await this.$request({
        // url: '/app/api/shop/orders',
        url: '/app/api/my/order/all',
        method: 'get',
        params: options
      });
      callback(result);
    },
    pushData(items, type) {
      console.log(items);
      console.log(type);
      if (type === 'set') this.itemList = items;
      if (type === 'add') this.itemList = this.itemList.concat(items);
      // this.itemList = items
    }
  }
};