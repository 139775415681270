var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "order-state-list"
  }, [_vm._l(_vm.orderStateList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "li",
      class: [{
        'cur': _vm.orderStateIndex == index
      }],
      on: {
        "click": function ($event) {
          return _vm.orderStateChange(index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), _c('div', {
    staticClass: "line",
    style: 'left: ' + _vm.orderStateIndex * 100 / _vm.orderStateList.length + '%'
  })], 2), _c('parent-list-content', {
    ref: "parentListContent",
    on: {
      "onDataLoad": _vm.onDataLoad,
      "pushData": _vm.pushData
    }
  }, [_c('div', {
    staticClass: "order-list"
  }, _vm._l(_vm.itemList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.itemClick(item);
        }
      }
    }, [_c('div', {
      staticClass: "time-and-state"
    }, [_c('div', {
      staticClass: "time"
    }, [_vm._v(_vm._s(item.orderInfo.createTime))]), _c('div', {
      staticClass: "state",
      class: {
        'state-lv': item.orderInfo.state == 3
      }
    }, [_vm._v(" " + _vm._s(item.orderInfo.stateStr == '已发货' ? '待收货' : item.orderInfo.stateStr) + " ")])]), _c('div', {
      staticClass: "order-good-box"
    }, _vm._l(item.orderInfo.goods, function (site) {
      return _c('div', {
        key: site.id,
        staticClass: "good"
      }, [_c('div', {
        staticClass: "cover"
      }, [_c('van-image', {
        attrs: {
          "width": "100%",
          "height": "100%",
          "fit": "cover",
          "src": site.goodCover
        }
      })], 1), _c('div', {
        staticClass: "info"
      }, [_c('div', {
        staticClass: "name"
      }, [_vm._v(_vm._s(site.goodName))])])]);
    }), 0), item.orderType == 1 ? _c('div', {
      staticClass: "total-box"
    }, [_c('div', {
      staticClass: "label"
    }, [_vm._v("合计：")]), _c('div', {
      staticClass: "unit"
    }, [_vm._v("¥")]), _c('div', {
      staticClass: "total"
    }, [_vm._v(_vm._s(item.orderInfo.integral ? `${item.orderInfo.integral}积分` : '') + _vm._s(item.orderInfo.integral && item.orderInfo.price ? '+' : '') + _vm._s(item.orderInfo.price ? `${item.orderInfo.price}元` : ''))])]) : _vm._e()]);
  }), 0)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };